import type {AtgResponse} from "@atg-shared/fetch-types";
import {pureFetch} from "@atg-shared/fetch";
import type {StaticContentType} from "@atg-sport-shared/sportsbook-types-static-content";
import {sportCloudFrontUrl} from "@atg-sport-shared/sportsbook-utils-helpers";
import type {ShortInfoResponse} from "@atg-sport-shared/sportsbook-types-short-info";
import type {HeroesResponse} from "@atg-sport-shared/sportsbook-types-hero";
import type {NonRegionalSportsType} from "@atg-sport-shared/sportsbook-types-non-regional-sport";
import type {CrossPromosType} from "@atg-content-shared/cross-promotion-types";

const contentUrl = `${sportCloudFrontUrl}/content`;

export const fetchHeroes = ({
    heroesContentUrl,
}: {
    heroesContentUrl: string | null | undefined;
}): Promise<AtgResponse<HeroesResponse>> => {
    const url = heroesContentUrl || `${contentUrl}/heroes.json`;
    return pureFetch(`${url}`);
};

export const fetchCrossPromotions = (): Promise<AtgResponse<CrossPromosType>> =>
    pureFetch(`${contentUrl}/crossPromotions.json`);

export const fetchStaticContent = (): Promise<AtgResponse<StaticContentType>> =>
    pureFetch(`${contentUrl}/staticPages.json`);

export const fetchNonRegionalSports = (): Promise<AtgResponse<NonRegionalSportsType>> =>
    pureFetch(`${contentUrl}/nonRegionalSports.json`);

export const fetchShortInfo = (): Promise<AtgResponse<ShortInfoResponse>> =>
    pureFetch(`${contentUrl}/shortInfo.json`);
