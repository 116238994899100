import {combineReducers} from "redux";
import {sportReducer} from "@atg-sport-shared/sport-reducer";
import {modals, modalData} from "atg-modals/modalReducer";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";
import shopShareReducer from "@atg-shop-shared/shops-domain-redux/src/shopShareReducer";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {kycQuestionnaire} from "@atg-aml-shared/kyc-domain";

const createRootReducer = () =>
    combineReducers({
        activeBreakpoint,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        limits,
        modals,
        modalData,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        sportsbook: sportReducer,
        shop: combineReducers({
            shopShare: shopShareReducer,
        }),
        kycQuestionnaire,
    });

export default createRootReducer;
