import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call, fetchSelectors} from "@atg-shared/fetch-redux";
import {LOADING_STATUS} from "@atg-shared/fetch-types";
import * as api from "@atg-sport-shared/sportsbook-utils-cms-api";
import type {CrossPromosType} from "@atg-content-shared/cross-promotion-types";

export const REQUEST_CROSS_PROMOTIONS = "sport/REQUEST_CROSS_PROMOTIONS";
export const RECEIVE_CROSS_PROMOTIONS = "sport/RECEIVE_CROSS_PROMOTIONS";

export type FetchCrossPromotionsAction = FetchAction<
    typeof REQUEST_CROSS_PROMOTIONS,
    typeof RECEIVE_CROSS_PROMOTIONS,
    CrossPromosType
>;

export const fetchCrossPromotions = (): FetchCrossPromotionsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_CROSS_PROMOTIONS,
        receiveAction: RECEIVE_CROSS_PROMOTIONS,
        shouldCallApi: (state) =>
            fetchSelectors.getLoadingStatus(state.sportsbook.crossPromotions).status !==
            LOADING_STATUS.OK,
        callApi: call(api.fetchCrossPromotions),
    },
});
